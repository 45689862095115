import styled, { device, themeGet } from "@styled";
import testimonialBG from "@data/images/bg/testimonials-quoteBG.png";

export const TestimonialWrappper = styled.section`
padding-top: 120px;
padding-bottom: 120px; 
position relative;
&::before {
  content: '';
  position: absolute;
  width: 184px;
  height: 156px;
  opacity: 0.05;
  background: url(${testimonialBG});
  left: 100px;
  top: 180px;
}
    ${device.medium} {
        padding-block: 50px;
        padding-inline: 25px;
    }
    ${device.large} {
        padding-block: 60px;
        padding-inline: 65px;
    }

    .swiper {
        padding: 30px 0;
        &-wrapper{
            align-items: center;
            ${device.medium} {
                padding: 15px;
            }
        }
        &-slide {
            background: #fff;
            border-radius: 6px;
            padding: 10px;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: ${themeGet("transition")};
            ${device.medium} {
                transform: scale(0.9);
            }
            ${device.xlarge} {
                padding: 20px;
            }
            &-prev, &-next {
                box-shadow: ${themeGet("shadows.sm")};
            }
            &-active {
                box-shadow: ${themeGet("shadows.sm")};
                z-index: 9;                
                ${device.medium} {
                    transform: scale(1.1);
                }
            }
        }
    }
`;
